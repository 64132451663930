import React, { useState } from 'react';
import "./faqs.scss";

const Faqs = () => {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  const accordionItems = [
    {
      question: "How does Enuncia.AI detect speech difficulties?",
      answer: 'Our app uses sophisticated AI algorithms to analyze your speech patterns in real time. By detecting nuances and variations in your speech, Enuncia.AI can identify specific areas for improvement and provide targeted exercises and feedback to help you progress.'
    },
    {
      question: "What kind of practice options does Enuncia.AI offer?",
      answer: "Enuncia.AI offers a wide range of text-based practice exercises tailored to your specific needs. Whether you're working on pacing, clarity, or overcoming specific speech difficulties, our app provides relevant and engaging exercises to help you improve."
    },
    {
      question: "Can Enuncia.AI help with all types of speech difficulties?",
      answer: "While Enuncia.AI is designed to assist with a variety of speech challenges, including stuttering, cluttering, aphonia, and aphasia, it's important to consult with a speech therapist for a comprehensive assessment. Our app can be a valuable tool in your speech improvement journey, offering additional support and practice opportunities."
    },
    {
      question: "How does Enuncia.AI provide feedback?",
      answer: "Based on your speech impediment, enuncia.AI analyzes your performance and offers feedback on areas of improvement. The app also tracks your progress over time, allowing you to see how your speech has evolved and where you can continue to refine your skills."
    },
    {
      question: "Are there any educational resources available within the app?",
      answer: `Yes, Enuncia.AI provides an array of educational videos and blogs that cover various aspects of speech improvement. These resources are designed to complement your practice exercises and provide deeper insights into effective communication strategies.`
    },

    {
      question: "Is Enuncia.AI suitable for all ages?",
      answer: `Our app is designed to be user-friendly and beneficial for a wide range of ages. However, the content and exercises are best suited for teenagers and adults. If you're considering Enuncia.AI for a younger child, we recommend supervision and guidance from a speech therapist.`
    },
    {
      question: "How can I track my progress with Enuncia.AI?",
      answer: `The app features a progress tracking tool that allows you to monitor your improvements over time. You can view detailed reports on your exercise completion, performance, and overall speech enhancement, helping you stay motivated and focused on your goals.`
    },
    {
      question: "Is my data secure with Enuncia.AI?",
      answer: `Absolutely. We take your privacy and data security seriously. All your data, including speech recordings and performance metrics, are securely stored and protected in accordance with the latest data protection regulations.`
    },
    {
      question: "How can I get started with Enuncia.AI?",
      answer: `Getting started is simple. Download the Enuncia.AI app from your preferred app store, create an account, and begin your speech improvement journey. Our intuitive app will guide you through the initial setup and help you start your personalized practice routine.`
    }
  ];

  return (
    <div className="accordion" id='faqs'>
      <div className='accordion-wrapper'>
        <div className="faqs-div">
          <p className='faqs-text'>FAQ’s</p>
          <p className='frequently-asked-text'>Frequently Asked </p>
          <p className='questions-text'>Questions </p>
        </div>

        <div className='list-item-div'>
          {accordionItems.map((item, index) => (
            <div className="accordion-item" key={index}>
              <input type="checkbox" id={`accordion${index}`} checked={activeAccordion === index} onChange={() => toggleAccordion(index)} />
              <label htmlFor={`accordion${index}`} className="accordion-item-title"><span className="icon"></span>{item.question}</label>
              <div className="accordion-item-desc" style={{ display: activeAccordion === index ? 'block' : 'none' }}>{item.answer}</div>
            </div>
          ))}
        </div>
      </div>
    </div>

  );
};

export default Faqs;
