import './App.css';
import MainRoute from './routes/MainRoute';

function App() {
  return (
    <>
     <MainRoute/>
    </>
  );
}

export default App;
