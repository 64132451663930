import React, { useState } from "react";
import "./tabgroup.scss";
import ComputerImage from "../../assets/computer-image.png";
import Tabimage from "../../assets/Group 1000004676.png";
import AppStoreImage from "../../assets/appstoreimage.png";
import PlayStoreImage from "../../assets/playstoreimge.png";

const tabsData = {
  "Our Mission": {
    main: "We envision a world where speech impediments are no longer obstacles to personal growth and connection. Our mission is to harness the power of AI to transform the lives of those who struggle to communicate, providing them with the tools to express themselves with clarity and confidence.",
    sub: "Rediscover the joy of self-expression and unlock your full potential by using the Speech Therapist in your pocket."
  },
  "Our Vision": {
    main: "In our vision for the future of speech therapy, we see a world where every individual embraces their unique voice with confidence and clarity. We envision a society where communication barriers are dismantled, fostering deeper connections and understanding among people from all walks of life. Our vision transcends mere speech correction; it envisions a world where every individual's voice is heard, understood, and valued. We envision a future where our innovative tools and techniques empower individuals to not only conquer speech impediments but also to thrive as articulate, empathetic communicators.",
    sub: "Join us on this journey of empowerment and self-discovery, where we strive to unlock the full potential of every voice."
  }
};

const TabGroup = () => {
  const [activeTab, setActiveTab] = useState("Our Mission");
  const [changeTabData, setChangeTabData] = useState(tabsData["Our Mission"].main);
  const [changeTabSubData, setChangeTabSubData] = useState(tabsData["Our Mission"].sub);

  const changeTab = (tab) => {
    setActiveTab(tab);
    setChangeTabData(tabsData[tab].main);
    setChangeTabSubData(tabsData[tab].sub);
  };

  return (
    <>
      <div className="tab-container"  >
        <div className="about-us-id" >

        </div>
        <div className="tab-title" id="aboutus">
          <p>Don't let your speech impediment hold you back! </p>
          <h1>
            Unchain Your Voice:
            <br />Use our AI-Powered Tools to Practice on the Go
          </h1>
        </div>
        <div className="tab-body">
          <div className="leftSec">
            <div className="tab">
              <div className="tabs">
                <TabButtons activeTab={activeTab} changeTab={changeTab} />
              </div>
            </div>
            <div className="tab-para">
              <p className="tab-desc">{changeTabData}</p>
              <h5>{changeTabSubData}</h5>
            </div>
          </div>
          <div className="rightSec">
            <div>
              <img src={ComputerImage} alt="" style={{marginTop:-210}}/>
              {/* <div className="button-container" ></div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const TabButtons = ({ activeTab, changeTab }) => {
  return (
    <div className="tab-buttons">
      {Object.keys(tabsData).map((tab) => (
        <button
          key={tab}
          className={tab === activeTab ? "active" : ""}
          onClick={() => changeTab(tab)}
        >
          {tab}
        </button>
      ))}
    </div>
  );
};

export default TabGroup;

