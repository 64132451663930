import React from "react";
import "./privacyPolicy.scss";
import { privacyPolicy } from "../../utils/privacyPolicyData";

const PrivacyPolicy = () => {
    return <>
        <div className="privacy-t-c-container">
            <h1 className="cms-heading-text">Privacy Policy</h1>
            <div dangerouslySetInnerHTML={{ __html: privacyPolicy }} style={{margin:30}}/>
        </div>
    </>
}

export default PrivacyPolicy;