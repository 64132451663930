import React, { useState } from "react";
import "./contact.scss";
import { toast } from "react-toastify";

const Contact = () => {

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };


  const fetchData = async () => {
    try {
      const requestBody = {
        email: formData.email,
        user_name: formData.name,
        message:formData.message
      };
      setFormData({
        name: "",
        email: "",
        message: "",
      });
      console.log("requestBody=>",requestBody)
      const response = await fetch('https://enuncia.ai:8000/queries/',{
        method:'POST',
        headers:{
          'Content-Type': 'application/json'
        },
        body:JSON.stringify(requestBody)
      });
      const jsonData = await response.json();

      if(jsonData.success==true){
        toast.success(jsonData.message);
      }
      else{
        toast.error(jsonData.message);
      }

    } catch (error) {
      console.log("error==>",error);
    } 
  };

  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const handleSubmit = (event) => {
    event.preventDefault();
  

    if(formData.name.trim()==""){
      toast.error("Please enter your name");
    }
    else if(formData.email==""){
      toast.error("Please enter your email");
    }
    else if(!regex.test(formData.email)){
      toast.error("Please enter valid email");
    }
    else if(formData.message.trim()==""){
      toast.error("Please enter your message");
    }
    else if(formData.message.split(" ").length>250){
      toast.error("Message can't be greater than 250 words");
    }
    else{
      fetchData();
    }
  };




  
  return (
    <>
      <div className="contact-container" id="contactus">
        <div className="leftSec">
          <div className="contact-title">
            <p>CONTACT US</p>
            <h1>
              Get in Touch: Reach Out for Inquiries, Collaborations, or Just a
              Friendly Chat
            </h1>
            <p className="contact-para">
              Have questions or feedback? We'd love to hear from you! Our
              dedicated team is here to assist with any inquiries or concerns.
              Drop us a message, and we'll get back to you promptly. Your input
              is valuable, and we're committed to providing the best support
              possible. Contact us today – your satisfaction is our priority
            </p>
          </div>
        </div>
        <div className="rightSec">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Email id"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <textarea
                id="message"
                name="message"
                placeholder="Add your comment here"
                value={formData.message}
                onChange={handleChange}
               
              />
            </div>
            <button type="submit">Submit</button>{" "}
          </form>
        </div>
      </div>
    </>
  );
};

export default Contact;
