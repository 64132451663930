import React from "react";
import styles from "./header.scss";
import EnunciaLogo from "../../../assets/enuncia.logo.svg";

const Header = () => {

  const handleHomeOnclick=()=>{
    const element = document.getElementById('banner');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const handleAboutUsOnclick=()=>{
    const element = document.getElementById('aboutus');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const handleOurFeaturesOnclick=()=>{
    const element = document.getElementById('ourfeatures');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const handleOurPriceOnclick=()=>{
    const element = document.getElementById('ourprice');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const handleContactUsOnclick=()=>{
    const element = document.getElementById('contactus');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  return (
    <>
      <header className="header">
        <nav>
          <div className="logo">
            <img src={EnunciaLogo} alt="" />
          </div>
          <input type="checkbox" id="menu-toggle" />
          <label htmlFor="menu-toggle" className="menu-icon">
            &#9776;
          </label>
          <ul className="menu">
            <li>
              <a  onClick={handleHomeOnclick} style={{ cursor: "pointer" }}>Home</a>
            </li>
            <li>
              <a  onClick={handleAboutUsOnclick} style={{ cursor: "pointer" }}>About Us</a>
            </li>
            <li>
              <a  onClick={handleOurFeaturesOnclick} style={{ cursor: "pointer" }}>Our Features</a>
            </li>
            <li>
              <a  onClick={handleOurPriceOnclick} style={{ cursor: "pointer" }}>Our Price</a>
            </li>
            <li>
              <a  onClick={handleContactUsOnclick} style={{ cursor: "pointer" }}>Contact Us</a>
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
};

export default Header;
