import React from "react";
import "./GroupSpeech.scss";
import RedDot from "../../assets/orangeCircle.png";
import SpeechAssesment from "../../assets/speech_assesment.svg";
import SpeechTherapy from "../../assets/speech_therapy.svg";
import SpeechAnalysis from "../../assets/speechAnalysis.svg";
import FirstImg from "../../assets/firstCircle.png";
import SecondImg from "../../assets/secondCircle.png";
import ThirdImg from "../../assets/thirdCircle.png";
import FourthImg from "../../assets/fourthCircle.png";
import ArrowIcon from "../../assets/arrow.png";
import AssessmentScore from "../../assets/assesmentScore.svg";

const GroupSpeechContainer = () => {

  return (
    <>
      <div className="group-speech-container-wrapper">
        <div className="dot-and-text-wrapper">
          <img src={RedDot} className="red-dot" />
          <p className="step-process-text">4 Step Process</p>
        </div>
        <p className="unlocking-clear-text">Unlocking Clear Speech</p>

        <div className="group-speech-row-wrapper">

          <div className="two-div-wrapper">
            <div className="steps-img-text-wrapper">
              <div className="image-and-text-wrapper">
                <img src={SpeechAssesment} className="step-img" />
                <img src={FirstImg} className="step-no" />
                <p className="step-no-text">Download the App and Sign Up </p>
              </div>
              <img src={ArrowIcon} className="arrow-icon" />
            </div>

            <div className="steps-img-text-wrapper">
              <div className="image-and-text-wrapper">
                <img src={SpeechAnalysis} className="step-img" />
                <img src={SecondImg} className="step-no" />
                <p className="step-no-text">Complete a Speech Assessment </p>
              </div>
              <img src={ArrowIcon} className="arrow-icon" />
            </div>
          </div>

          <div className="two-div-wrapper">
          <div className="steps-img-text-wrapper">
            <div className="image-and-text-wrapper">
              <img src={AssessmentScore}  className="step-img"/>
              <img src={ThirdImg} className="step-no" />
              <p className="step-no-text">Receive an Analysis</p>
            </div>
            <img src={ArrowIcon} className="arrow-icon" />
          </div>

          <div className="steps-img-text-wrapper">
            <div className="image-and-text-wrapper">
              <img src={SpeechTherapy} className="step-img" />
              <img src={FourthImg} className="step-no" />
              <p className="step-no-text">Begin your Practice</p>
            </div>
          </div>
          </div>

        </div>

      </div>
    </>
  );
};

export default GroupSpeechContainer;
