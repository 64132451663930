import React from "react";
import "./tAndC.scss";
import { termsAndConditionData } from "../../utils/termsAndConditionData";

const TAndC = () => {
    return <>
        <div className="t-c-container">
            <h1 className="tc-heading-text">Terms of Services</h1>
            <div dangerouslySetInnerHTML={{ __html: termsAndConditionData }} style={{margin:30}} />
        </div>
    </>
}

export default TAndC;