import React from "react";
import "./ourPricing.scss";
import PriceCardLadyImg from "../../assets/priceCardImg.png";
import ArrowIcon from "../../assets/arrowIcon.png";
import StarIcon from "../../assets/starIcon.png";
import CheckIcon from "../../assets/checkIcon.png";

const OurPricing = () => {
  return (
    <div className="our-pricing-parent-container" id="ourprice">
      <p className="our-price-text">Our Pricing</p>
      <p className="choose-the-perfect-plan-text">
        Choose the perfect plan for your needs
      </p>
      <p className="save-plan-text">
        <span className="save-text">Save 15%</span> on yearly plan!
      </p>
      <div style={{overflow:'auto'}}>
        <div className="card-container-wrapper">
          <div className="card-containers">
            <div className="price-card">
              <p className="save-more"> Save More </p>
              <p className="with-best-pricing"> With Best Pricing.</p>
              <p className="choose-plan-text">Choose a plan and get started</p>
              <div className="img-and-arrow-icon">
                <img src={PriceCardLadyImg} alt="" className="price-card-img" />
                <img src={ArrowIcon} alt="" className="arrow-icon" />
              </div>
            </div>
          </div>
          <div className="premium-card-container">
            <div className="price-card">
              <p className="stater"> Starter </p>
              <p className="best-for-stuttering">Best for Stuttering.</p>
              <div style={{ position: "relative", marginTop: 5 }}>
                <img src={StarIcon} className="star-icon" />
                <p className="free-text">Free</p>
              </div>
              <p className="what-will-you-get-text">What you’ll get:</p>
              <div className="check-icon-and-text-container">
                <img src={CheckIcon} className="check-icon" />
                <p className="check-list-item">
                  Access to Diagnostics and Therapy for Stuttering
                </p>
              </div>
              <div className="check-icon-and-text-container">
                <img src={CheckIcon} className="check-icon" />
                <p className="check-list-item">
                  A Front-Row seat to our Blog Posts and Videos
                </p>
              </div>
            </div>
          </div>
          <div className="premium-card-container">
            <div className="price-card">
              <p className="stater"> Pro </p>
              <p className="best-for-stuttering">
                Best for Cluttering, Lisping, and/or Aphonia.
              </p>
              <p className="doller-price" style={{ marginTop: 30 }}>
                $15 <span className="month-text">/month</span>
              </p>
              <div className="yearly-text">
                <p className="doller-price">
                  $140 <span className="month-text">/year</span>
                </p>
                <p className="save-percentage">save 20 %</p>
              </div>
              <p className="what-will-you-get-text" style={{ marginTop: 22 }}>
                What you’ll get:
              </p>
              <div className="check-icon-and-text-container">
                <img src={CheckIcon} className="check-icon" />
                <p className="check-list-item">
                  Access to Diagnostics and Therapy for Stuttering
                </p>
              </div>
              <div className="check-icon-and-text-container">
                <img src={CheckIcon} className="check-icon" />
                <p className="check-list-item">
                  Access to Diagnostics and Therapy for Lisping
                </p>
              </div>
              <div className="check-icon-and-text-container">
                <img src={CheckIcon} className="check-icon" />
                <p className="check-list-item">
                  Access to Diagnostics and Therapy for Aphonia
                </p>
              </div>
              <div className="check-icon-and-text-container">
                <img src={CheckIcon} className="check-icon" />
                <p className="check-list-item">
                  Access to Diagnostics and Therapy for Cluttering
                </p>
              </div>
              <div className="check-icon-and-text-container">
                <img src={CheckIcon} className="check-icon" />
                <p className="check-list-item">
                  A Front-Row seat to our Blog Posts and Videos
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurPricing;
