import React from "react";
import "./introducingBanner.scss";
import Image from "../../assets/fisrtImage.svg";
import GoogleImage from "../../assets/Google Play Badge.png";
import PlayStoreImage from "../../assets/Group.png";
import ArrowImage from "../../assets/50.png";

const SingleBanner = () => {
  return (
    <>
      <div className="banner-container" >
        <div className="leftSec ">
          <div className="title-container">
            <div style={{paddingTop:"90px",marginTop:"-90px"}} id="banner">
              <p>INTRODUCING&nbsp;<span>ENUNCIA.AI</span>
                </p>
            </div>
            <div className="tittle-text-container" >
              The Worlds First <span>AI-Powered </span>Speech Therapy App For Stuttering, Cluttering, Aphonia, And Lisping
            </div>
            <div>
              <p className="craft-text">
              Explore our ground-breaking speech impediment diagnostic and treatment tools
              </p>
            </div>
          </div>
          <div className="arrow-image">
            <img src={ArrowImage} alt="" />
          </div>
          <div className="button-container">
            <div className="btn-group">
              <img src={GoogleImage} alt="" />
              <img src={PlayStoreImage} alt="" className="leftImage" />
            </div>
          </div>
        </div>
        <div className="rightSec">
          <img src={Image} alt="" />
        </div>
      </div>
    </>
  );
};

export default SingleBanner;
