import React from "react";
import SingleBanner from "./introducingBanner/introducingBanner";
import TabGroup from "./tabcontainer/TabGroup";
import CardContainer from "../components/shared/common-card/CardContainer";
import CommonCard from "../components/shared/second-card/CommonCard";
import Comparision from "./comparision/Comparision";
import OurPricing from "./ourPricing/OurPricing";
import GroupSpeechContainer from "./speechassement/GroupSpeechContainer";
import SpeechBlock from "./speechBlock/SpeechBlock";
import Contact from "./contactus/Contact";
import Faqs from "./faqs/Faqs";
import Footer from "../components/layouts/footer/Footer";
import Header from "../components/layouts/header/Header";
import FistImage from "../assets/contaner1.png";
import SecondImage from "../assets/card-images/Group 1261153105.png";
import ThirdImage from "../assets/card-images/Group 1261153104.png";
import FourthImage from "../assets/card-images/Group.png";
import FifthImage from "../assets/card-images/OBJECTS.png";

const cardData = [{
    titleOne: 'Overall',
    titleTwo: 'Practice',
    content: 'Get a daily practice routine completely tailored to your specific speech goals. Our AI analyzes your speech patterns to pinpoint areas for improvement and delivers:',
    img: FistImage,
    description: [
        {
            text: "Focused Text Exercises:",
            subText: "Short, targeted passages designed to challenge your areas of difficulty. Practice troublesome sounds and word patterns in a supportive environment."
        },
        {
            text: "Specific Pronunciation Cues:",
            subText: "Clear breakdowns of how to form challenging sounds, along with visual and audio examples for easy practice."
        },
        {
            text: "Personalized Improvement Tips:",
            subText: "Insights into your speech habits and actionable advice on posture, breathing, and other techniques to enhance your communication skills"
        }
    ]
},
{
    titleOne: 'Lisping',
    titleTwo: 'Practice',
    content: "Unlock Clear Articulation: Our Lisping Practice Program is designed to guide you through a comprehensive journey towards impeccable speech. With a focus on personalized exercises and expert guidance, we address and correct lisping patterns, fostering precise pronunciation. Engage in targeted drills that hone in on specific speech sounds, allowing you to build a strong foundation for articulate communication. Through consistent practice, you'll gain the confidence to express yourself clearly and effectively. Our program not only targets lisping but also cultivates overall oral proficiency, ensuring a well-rounded enhancement of your speech capabilities. Join us on this transformative path, where every session brings you closer to the mastery of fluent and eloquent expression.",
    img: SecondImage,
    description: [
      
    ]
},
{
    titleOne: 'Stuttering',
    titleTwo: 'Practice',
    content: "Our Stuttering Practice mode provides a safe and supportive environment to develop fluency-enhancing techniques. This feature includes:",
    img: ThirdImage,
    type: 2,
    description: [
        {
            text: "Address Specific Words:",
            subText: "Tools to identify words you struggle with."
        },
        {
            text: "Flexible Rate Control:",
            subText: "Tools to practice slowing or varying speech rate, promoting a sense of control and ease of speech."
        },
        {
            text: "Progress Tracking:",
            subText: "Track occurrences of stuttering moments to discover patterns and celebrate improvement over time."
        }
    ]
},
{
    titleOne: 'Aphonia',
    titleTwo: 'Practice',
    content: "Rediscover Your Voice: Our Aphonia Practice Program is a tailored journey towards reclaiming the power of speech. Through specialized exercises and compassionate guidance, we work towards overcoming aphonia, gradually restoring vocal strength and clarity. Dive into targeted vocal exercises designed to rehabilitate your voice, building resilience and endurance. Our program focuses on breathing techniques, vocal warm-ups, and gradual progression, allowing you to regain control over your voice. Each session is crafted to empower you with the tools necessary to express yourself confidently. Join us on this transformative path, where every step brings you closer to a renewed and resonant voice, unlocking the joy of communication once again",
    img: FourthImage,
    type: 2,
    description: [
    ]
},
{
    titleOne: 'Cluttering',
    titleTwo: 'Practice',
    content: "Refine Your Speech: Our Cluttering Practice Program is a dedicated journey towards achieving clear and organized communication. Through a combination of structured exercises and personalized coaching, we address the challenges of cluttered speech, helping you regain control and coherence. Engage in targeted drills that focus on pacing, articulation, and rhythm, fostering a smoother and more effective communication style. Our program is designed to build self-awareness and provide practical strategies for managing clutter in speech patterns. With consistent practice, you'll develop the skills to express yourself with confidence and clarity.",
    img: FifthImage,
    type: 1,
    description: [
    ]
},
]

const HomePage = () => {
    return <>
        <Header />
        <SingleBanner />
        <TabGroup />
        <CardContainer data={cardData[0]} />
        <CommonCard data={cardData[2]} />
        <CardContainer data={cardData[1]} />
        <CommonCard data={cardData[3]} />
        <CardContainer data={cardData[4]} />
        <Comparision />
        <OurPricing />
        <GroupSpeechContainer />
        <SpeechBlock />
        <Contact />
        <Faqs />
        <Footer />
    </>
}

export default HomePage;