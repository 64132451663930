import React from "react";
import "./comparision.scss";
import EnunciaAiLogo from "../../assets/enunciaAiLogo.svg";
import WhiteCheckSign from "../../assets/whiteCheckSign.png";

const Comparision = () => {

    return <div className="comparision-parent">
        <p className="advantage-text">Advantages Over Competitors: Why Choose Us?</p>

        <div className="comperision-row">
            

            <div className="competitior-container">
                <img src={EnunciaAiLogo} />
                <div className="comparision-content-container">
                    <p className="content-header"><img src={WhiteCheckSign} className="white-check-sign" /> Tailored AI Algorithms:</p>
                    <p className="content-text">Utilizes advanced, proprietary AI algorithms specifically designed for detecting and improving a wide range of speech difficulties.</p>
                </div>

                <div className="comparision-content-container">
                    <p className="content-header"><img src={WhiteCheckSign} className="white-check-sign" /> Text-Based Practice Options:</p>
                    <p className="content-text">Provides a variety of engaging text-based exercises tailored to individual needs, enhancing the learning experience.</p>
                </div>

                <div className="comparision-content-container">
                    <p className="content-header"><img src={WhiteCheckSign} className="white-check-sign" /> Progress Tracking:</p>
                    <p className="content-text">Features sophisticated progress tracking tools that allow users to monitor their improvements and set goals.</p>
                </div>

                <div className="comparision-content-container">
                    <p className="content-header"><img src={WhiteCheckSign} className="white-check-sign" /> Educational Resources:</p>
                    <p className="content-text">Includes access to a library of educational videos and blogs to complement the practical exercises, offering a holistic approach to speech improvement.</p>
                </div>
            </div>

            <div  className="vs-text">VS</div>
            <div className="competitior-container">
                <p className="our-competitors-text">Our Competitors</p>
                <div className="comparision-content-container">
                    <p className="content-header"><img src={WhiteCheckSign} className="white-check-sign" /> Generic Solutions:</p>
                    <p className="content-text">More generic solutions which aren’t tailored to your specific speech impediments. </p>
                </div>

                <div className="comparision-content-container">
                    <p className="content-header"><img src={WhiteCheckSign} className="white-check-sign" /> Limited Practice Modes:</p>
                    <p className="content-text">Often have fewer or less varied practice options, which may not cater to all users' preferences or requirements.</p>
                </div>

                <div className="comparision-content-container">
                    <p className="content-header"><img src={WhiteCheckSign} className="white-check-sign" /> Basic or No Tracking:</p>
                    <p className="content-text">Competitors might offer only basic progress tracking or none at all, making it harder for users to see their development over time.</p>
                </div>

                <div className="comparision-content-container">
                    <p className="content-header"><img src={WhiteCheckSign} className="white-check-sign" /> Limited Educational Content:</p>
                    <p className="content-text">May provide fewer educational materials, focusing mainly on exercises without supporting content.</p>
                </div>
            </div>
        </div>

    </div>
}

export default Comparision;