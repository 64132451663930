import React from "react";
import "./commoncard.scss";
import BackgroundImage from "../../../assets/6.png";
import DotImg from "../../../assets/dot.png";

const CommonCard = (props) => {
  console.log(props);
  const { titleOne, titleTwo, content, img, type, description } = props.data;

  return (
    <div
      className="cmmonCard-container"
      style={{ background: type === 1 ? `url(${BackgroundImage})` : "#fff" }}
    >
      <div className="rightCommonSec">
        <div className="banner">
          <p className="bulletPoint"></p>
          <p>Our Features</p>
          <hr />
        </div>
        <div className="textContainer">
          <h5 style={{ color: type == 1 ? `#FFFFFF` : "#000" }}>
            <span>{titleOne}</span> {titleTwo}
          </h5>
        </div>

        <div className="titleContainer">
          <p style={{ color: type == 1 ? `#FFFFFF` : "#2d2d2d" }}>{content}</p>

          {description.map((desc, index) => {
            return (
              <div className="common-dot-div">
                <img src={DotImg} style={{height:16,width:16,marginTop:7}}/>
                <p className='common-dot-sub-text'>
                  <span className='common-dot-text'>{description[index].text}</span>
                  {description[index].subText}</p>
              </div>
            
            )
          })}

        </div>
      </div>
      <div className="leftCommonSec">
        <img src={img} alt="" />
      </div>
    </div>
  );
};

export default CommonCard;
