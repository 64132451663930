import React from "react";
import HomePage from "../pages/homePage";
import { Route, Routes } from 'react-router-dom';
import PrivacyPolicy from "../pages/privacyPolicy/PrivacyPolicy";
import TAndC from "../pages/tAndC/TAndC";


const MainRoute = () => {
    return (
        <>
            <Routes>
                <Route path='/' element={<HomePage />} />
                <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                <Route path='/t-and-c' element={<TAndC />} />
            </Routes>
        </>
    )
}

export default MainRoute;