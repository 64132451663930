import React from "react";
import "./SpeechBlock.scss";
import GoogleImage from "../../assets/Google Play Badge.png";
import PlayStoreImage from "../../assets/Group.png";
import MobileImage from "../../assets/mobileFrame.png";

const SpeechBlock = () => {
  return (
    <>
      <div className="speech-container">
        <div className="leftSec">
          <div className="speech-title">
            <h1>
              Don't Let Speech
              <br /> Impediments Hold You
              <br /> Back{" "}
            </h1>
            <p>Download the App: Reclaim Your Voice with AI</p>
          </div>
          <div className="speech-button">
            <p>Get the app</p>
            <div className="button-container">
              <div className="btn-group">
                <img src={GoogleImage} alt="" />
                <img src={PlayStoreImage} alt="" className="leftImage" />
              </div>
            </div>
          </div>
        </div>
        <div className="rightSec">
          <img src={MobileImage} alt="" />
        </div>
      </div>
    </>
  );
};

export default SpeechBlock;
