import React from "react";
import "./cardcontainer.scss";
import Container1 from "../../../assets/contaner1.png";
import DotImg from "../../../assets/dot.png";

const CardContainer = (props) => {
  const { titleOne, titleTwo, content, img, description } = props.data;

  return (
    <div className="card-container" id="ourfeatures" >
      <div className="leftSec">
        <img src={img} alt="" />
      </div>
      <div className="rightSec">
        <div className="banner">
          <p className="bulletPoint"></p>
          <p>Our Features</p>
          <hr />
        </div>
        <div className="textContainer">
          <h5>
            <span>{titleOne}</span> {titleTwo}
          </h5>
        </div>

        <div className="titleContainer">
          <p>{content}</p>
        </div>

        {description.map((desc, index) => {
          return (
            <div className="dot-div">
              <img src={DotImg} style={{height:16,width:16,marginTop:7}}/>
              <p className='dot-sub-text'>
                <span className='dot-text'>{desc.text}</span>{desc.subText}</p>
            </div>
          )
        })}
      </div>
    </div>
  );
};

export default CardContainer;
