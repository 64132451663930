import React from "react";
import "./footer.scss";
import FooterLogo from "../../../assets/footerLogo.svg";
import { useNavigate } from "react-router-dom";

const Footer = () => {

  const navigaet=useNavigate();
 
  const handleAboutUsOnclick=()=>{
    const element = document.getElementById('aboutus');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const handleOurFeaturesOnclick=()=>{
    const element = document.getElementById('ourfeatures');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const handleOurPriceOnclick=()=>{
    const element = document.getElementById('ourprice');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const handleContactUsOnclick=()=>{
    const element = document.getElementById('contactus');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const hanldeFaqsOnClick=()=>{
    const element = document.getElementById('contactus');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const handlePrivacyPolicyOnClick=()=>{
    navigaet('/privacy-policy');
  }

  const handleTAndCOnClick=()=>{
    navigaet('/t-and-c');
  }

  return (
    <>
      <footer className="footer-container">
        <div className="left-sec">
          <img src={FooterLogo} alt="Logo" />
          <p >
            <span className="footer-text" style={{ color: "white", opacity: 0.59 }} >Reclaim Your Voice,</span>
            <br /> <span className="footer-text" style={{ color: "white", opacity: 0.59 }}>with</span> <span className="footer-text" style={{ color: "white", opacity: 1 }}>AI</span>
          </p>
        </div>
        <div className="right-sec">
          <ul className="footer-links">
            <li>
              <ul className="sub-links">
                <li style={{ cursor: "pointer" }} onClick={handleAboutUsOnclick} >About Us</li>
                <li style={{ cursor: "pointer" }} onClick={handleOurPriceOnclick}>Our Price</li>
                <li style={{ cursor: "pointer" }} onClick={handleContactUsOnclick}>Contact us</li>
              </ul>
            </li>
            <li>
              <ul className="sub-links">
                <li style={{ cursor: "pointer" }} onClick={hanldeFaqsOnClick}>FAQ's</li>
                <li style={{ cursor: "pointer" }} onClick={handlePrivacyPolicyOnClick}>Privacy Policy</li>
                <li style={{ cursor: "pointer" }} onClick={handleTAndCOnClick}>Terms of Services</li>
              </ul>
            </li>
            <li>
              <ul className="sub-links">
              <li style={{ cursor: "pointer" }} onClick={handleOurFeaturesOnclick}>Our Features</li>
                <li>Email -  support@enuncia.ai</li>
              </ul>
            </li>
          </ul>
        </div>
      </footer>
      <div className="copyright-container">
        <p>©2024 Enuncia.AI. All right reserved</p>
      </div>
    </>
  );
};

export default Footer;
